import React from 'react'
import Layout from '../../components/layout'
import PageStructure from '../../components/pagestructure'
import SEO from '../../components/seo'
import { ABOUTUS } from '../../components/constant'
const ListofIRSAPresident = () => {
  return (
    <div>
      <Layout>
        <SEO title="List of IRSA President" />
        <PageStructure
          title="List of IRSA President"
          desc="TESTIG"
          mode={ABOUTUS}
          content={`
          
          <b style="font-family:'Volkhov';">President</b>
          <br/>
          Binay Kumar Jha, IRS
          <br/><br/>
          <b style="font-family:'Volkhov';">Vice President</b>
          <br/>
          Rupinder Kaur Brar, IRS
          <br/><br/>
          <b style="font-family:'Volkhov';">General Secretary</b>
          <br/>
          Prashant Bhushan, IRS
          <br/><br/>
          <b style="font-family:'Volkhov';">Joint General Secretary</b>
          <br/>
          <ol style="line-height:3">
            <li>YVST Sai, IRS</li>
            <li>M Mathivanan, IRS</li>
            <li>Shri Prakash Dubey, IRS</li>
          </ol>
          <b style="font-family:'Volkhov';">Treasurer</b>
          <br/>
          Harshit Bansal, IRS
          <br/><br/>
          <b style="font-family:'Volkhov';">Executive Committee</b>
          <br/>
          <ol style="line-height:3">
            <li>C Deepak Singh, IRS</li>
            <li>Sapna Bhatia, IRS</li>
            <li>Santosh Kumar Karnani, IRS</li>
            <li>Inder Solanki, IRS</li>
            <li>Janardhan S, IRS</li>
            <li>Rohit Raj, IRS</li>
            <li>Anurag Tripathi, IRS</li>
            <li>Saagar Srivastava, IRS</li>
            <li>Shakil Ahmad Ganie, IRS</li>
          </ol>
          <br/><br/>

          `}
        />
      </Layout>
    </div>
  )
}

export default ListofIRSAPresident
